import React from 'react';
import Link from 'gatsby-link';
import tomImage from '../../../img/tom.png';
import CV from './CV.pdf';
import './OrtomBrief.scss';

const OrtomBrief = () => (
  <div className="container mobile-container">
    <div className="columns is-fullhd">
      <div className="column is-half tomImage">
        <img className="" src={tomImage} alt="Tom man standing proudly" style={{ height: 'auto', width: '100%' }} />
      </div>
      <div className="column is-half textWithButton ">
        <div>
          <h3>Proven AI consulting expertise in delivering impactful data and machine learning solutions.</h3>
          <br />
          <p>
          Ortom  is led by Tom Liptrot, a Manchester-based artificial intelligence and machine learning expert with a balanced technical and business perspective.
          </p>
          <br />
          <p>
          Tom combines expertise in data science, machine learning, large language models, and business strategy. His practical experience spans multiple sectors including healthcare, pharmaceuticals, retail, and software development.
          </p>
          <br />
          <p>
          Tom works with Python, R, and C++ as needed, implementing both traditional algorithms and generative AI where they deliver business value. He navigates cloud environments with confidence (AWS, GCP, Azure) and offers specialized knowledge in Natural Language Processing (NLP), generative AI applications, predictive modeling, healthcare applications, forecasting, and inventory optimization.
          </p>

        </div>
        <div style={{ margin: '32px 0' }} />
        <a href={CV} download>
          <button type="button " className=" button button-trans-arrow is-outlined is-rounded is-fullwidth OTButton-secondary OTButton">Download my CV</button>
        </a>
      </div>
    </div>
    <div className="gap" />
  </div>
);

export default OrtomBrief;
