import React, { useState, useEffect } from 'react';

const Tagline = () => {
  // Define the entire tagline text with newlines
  const fullText = "ORTOM: AI\n CONSULTANCY FOR\n TECH INNOVATORS";
  
  // Split the text into lines first
  const textLines = fullText.split('\n');
  
  // Create an array of arrays, where each inner array contains the letters for a line
  const [lettersByLine, setLettersByLine] = useState(
    textLines.map(line => 
      line.split('').map(letter => ({
        letter,
        isTechFont: letter !== ' ' && letter !== '&' && Math.random() > 0.7
      }))
    )
  );
  
  // Function to randomly change which letters use the tech font
  const randomizeTechFont = () => {
    setLettersByLine(prevLettersByLine => 
      prevLettersByLine.map(line => 
        line.map(({ letter }) => ({
          letter,
          isTechFont: letter !== ' ' && letter !== '&' && Math.random() > 0.7
        }))
      )
    );
  };
  
  // Set up the interval to change the tech font letters every second
  useEffect(() => {
    const intervalId = setInterval(randomizeTechFont, 3000);
    
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <div className="container is-fluid mobile-container">
      <div>
        <h1>
          {lettersByLine.map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
              {line.map((letterObj, letterIndex) => (
                <span 
                  key={`${lineIndex}-${letterIndex}`}
                  className={letterObj.isTechFont ? "title--techfont" : ""}
                >
                  {letterObj.letter}
                </span>
              ))}
              {lineIndex < lettersByLine.length - 1 && <br />}
            </React.Fragment>
          ))}
        </h1>
      </div>
    </div>
  );
};

export default Tagline;