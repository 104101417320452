import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();
import Layout from '../components/Layout';
import Landing from '../components/LandingPage/Landing';

export const IndexPageTemplate = ({
  frontmatter,
}) => (
  <div>
    <Landing frontmatter={frontmatter} />
  </div>
);

IndexPageTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        frontmatter={frontmatter}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        testimonials {
          quote
          author
          role
        }
        intro {
          tagline
          paragraph
          paragraph2
          buttonText
        }
      }
    }
  }
`;
